import React, { Suspense } from 'react';

import { CircularProgress } from '@mui/material';

interface ComponentProps {
    children: React.ReactNode;
}

const Component: React.FC<ComponentProps> = ({ children }: ComponentProps) => {
    return <Suspense fallback={<CircularProgress className="loader" />}>{children}</Suspense>;
};

export default Component;
