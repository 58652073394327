import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material';

import { PRIMARY_COLOR } from '@/constants';
import '@/index.scss';
import reportWebVitals from '@/reportWebVitals';
import { AnalyticsContextProvider } from '@/utils/analytics-context';
import Suspensed from '@/utils/route/Suspended';
import { SearchContextProvider } from '@/utils/search-context';

const Home = React.lazy(() => import('@/pages/Home'));
const DestinationList = React.lazy(() => import('@/pages/DestinationList'));
const DestinationDetail = React.lazy(() => import('@/pages/DestinationDetail'));
const PageNotFound = React.lazy(() => import('@/pages/NotFound'));
const BlogList = React.lazy(() => import('@/pages/Blog/BlogList'));
const BlogArticleDetail = React.lazy(() => import('@/pages/Blog/BlogArticleDetail'));

const rootNode = document.getElementById('root');

if (rootNode == null) {
    throw new Error('No root element');
}

const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            light: '#F8F8FF',
            dark: '#420194',
        },
        secondary: {
            main: '#0030C9',
            light: '#96AFFF',
            dark: '#032287',
        },
        error: {
            main: '#EC6762',
            light: '#FDDEDD',
            dark: '#BB504C',
        },
        warning: {
            main: '#EBBC17',
            light: '#FFF0D9',
            dark: '#BE9400',
        },
        success: {
            main: '#2AC9BF',
            light: '#DEF2F1',
            dark: '#21958E',
        },
        info: {
            main: '#1B1E33',
            light: '#8690B0',
            dark: '#555972',
        },
    },
    typography: {
        fontFamily: [
            'Urbanist',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
    },
});

const root = ReactDOM.createRoot(rootNode);

const ROUTES = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/destination',
        element: <DestinationList />,
    },
    {
        path: '/destination/detail/:arrivalCitySlug?/:departureCitySlug?',
        element: <DestinationDetail />,
    },
    {
        path: '/blog',
        element: <BlogList />,
    },
    {
        path: '/blog/:articleSlug',
        element: <BlogArticleDetail />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
];

root.render(
    <AnalyticsContextProvider>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SearchContextProvider>
                    <Routes>
                        {ROUTES.map(({ path, element }) => (
                            <Route key={path} path={path} element={<Suspensed>{element}</Suspensed>} />
                        ))}
                    </Routes>
                </SearchContextProvider>
            </BrowserRouter>
        </ThemeProvider>
    </AnalyticsContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
