import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { Interest } from '@/enums/interest';
import { City } from '@/interfaces/city';
import { Destination } from '@/interfaces/destination';
import { DestinationFilters } from '@/interfaces/destination-filters';
import { DestinationListApiParams, NBR_RESULTS_PER_PAGE } from '@/utils/search';

interface ContextState {
    // search results
    destinations?: Destination[];
    setDestinations: (destinations: Destination[]) => void;
    departureCity?: City;
    setDepartureCity: (departureCity: City) => void;

    // filters
    interests: Interest[];
    setInterests: (interests: Interest[], updateSearchParams?: boolean) => void;
    departureDate?: Date;
    setDepartureDate: (departureDate?: Date, updateSearchParams?: boolean) => void;
    departureCitySlug: string;
    setDepartureCitySlug: (departureCitySlug: string, updateSearchParams?: boolean) => void;
    arrivalCitySlugs?: string[];
    setArrivalCitySlugs: (arrivalCitySlugs: string[]) => void;
    destinationFilters: DestinationFilters;
    setDestinationFilters: (destinationFilters: DestinationFilters, updateSearchParams?: boolean) => void;
    readSearchParams: () => DestinationListApiParams | void;
}

const initialState: ContextState = {
    destinations: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDestinations: () => {},
    departureCity: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDepartureCity: () => {},
    interests: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setInterests: () => {},
    departureDate: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDepartureDate: () => {},
    departureCitySlug: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setArrivalCitySlugs: () => {},
    arrivalCitySlugs: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDepartureCitySlug: () => {},
    destinationFilters: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDestinationFilters: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    readSearchParams: () => {},
};

const Context = createContext<ContextState>(initialState);

const useContext_ = () => useContext(Context);

interface ContextActionDestinations {
    type: 'UPDATE_DESTINATIONS';
    payload: Destination[];
}

interface ContextActionDepartureCity {
    type: 'UPDATE_DEPARTURE_CITY';
    payload: City;
}

interface ContextActionInterests {
    type: 'UPDATE_INTERESTS';
    payload: Interest[];
}

interface ContextActionDepartureDate {
    type: 'UPDATE_DEPARTURE_DATE';
    payload?: Date;
}

interface ContextActionDepartureCitySlug {
    type: 'UPDATE_DEPARTURE_CITY_SLUG';
    payload: string;
}

interface ContextActionArrivalCitySlugs {
    type: 'UPDATE_ARRIVAL_CITY_SLUGS';
    payload: string[];
}

interface ContextActionDestinationFilters {
    type: 'UPDATE_DESTINATION_FILTERS';
    payload: DestinationFilters;
}

type ContextAction =
    | ContextActionDestinations
    | ContextActionDepartureCity
    | ContextActionInterests
    | ContextActionDepartureDate
    | ContextActionDepartureCitySlug
    | ContextActionArrivalCitySlugs
    | ContextActionDestinationFilters;

const reducer = (state: ContextState, action: ContextAction): ContextState => {
    switch (action.type) {
        case 'UPDATE_DESTINATIONS':
            return {
                ...state,
                destinations: action.payload,
            };

        case 'UPDATE_DEPARTURE_CITY':
            return {
                ...state,
                departureCity: action.payload,
            };

        case 'UPDATE_INTERESTS':
            return {
                ...state,
                interests: action.payload,
            };

        case 'UPDATE_DEPARTURE_DATE':
            return {
                ...state,
                departureDate: action.payload,
            };

        case 'UPDATE_DEPARTURE_CITY_SLUG':
            return {
                ...state,
                departureCitySlug: action.payload,
            };

        case 'UPDATE_ARRIVAL_CITY_SLUGS':
            return {
                ...state,
                arrivalCitySlugs: action.payload,
            };

        case 'UPDATE_DESTINATION_FILTERS':
            return {
                ...state,
                destinationFilters: action.payload,
            };

        default:
            return state;
    }
};

const Provider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    const setSearchParamsIfDifferent = (name: string, value: string | null) => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get(name) === value) {
            return;
        }

        if (value === null) {
            searchParams.delete(name);
        } else {
            searchParams.set(name, value);
        }

        navigate({ pathname: window.location.pathname, search: '?' + searchParams.toString() });
    };

    const setDestinations = useCallback((destinations: Destination[]) => {
        dispatch({
            type: 'UPDATE_DESTINATIONS',
            payload: destinations,
        });
    }, []);

    const setDepartureCity = useCallback((departureCity: City) => {
        dispatch({
            type: 'UPDATE_DEPARTURE_CITY',
            payload: departureCity,
        });
    }, []);

    const setInterests = useCallback((interests: Interest[], updateSearchParams?: boolean) => {
        dispatch({
            type: 'UPDATE_INTERESTS',
            payload: interests,
        });

        updateSearchParams &&
            setSearchParamsIfDifferent('interests', interests.length > 0 ? interests.join(',') : null);
    }, []);

    const setDepartureDate = useCallback((departureDate?: Date, updateSearchParams?: boolean) => {
        dispatch({
            type: 'UPDATE_DEPARTURE_DATE',
            payload: departureDate,
        });

        updateSearchParams &&
            setSearchParamsIfDifferent('departureDateTime', departureDate ? departureDate.toISOString() : null);
    }, []);

    const setDepartureCitySlug = useCallback((departureCitySlug: string, updateSearchParams?: boolean) => {
        dispatch({
            type: 'UPDATE_DEPARTURE_CITY_SLUG',
            payload: departureCitySlug,
        });

        updateSearchParams && setSearchParamsIfDifferent('departureCity', departureCitySlug);
    }, []);

    const setArrivalCitySlugs = useCallback((arrivalCitySlugs: string[], updateSearchParams?: boolean) => {
        dispatch({
            type: 'UPDATE_ARRIVAL_CITY_SLUGS',
            payload: arrivalCitySlugs,
        });

        updateSearchParams &&
            setSearchParamsIfDifferent(
                'arrivalCities',
                arrivalCitySlugs.length > 0 ? arrivalCitySlugs.join(',') : null,
            );
    }, []);

    const setDestinationFilters = useCallback(
        (destinationFilters: DestinationFilters, updateSearchParams?: boolean) => {
            dispatch({
                type: 'UPDATE_DESTINATION_FILTERS',
                payload: destinationFilters,
            });

            if (updateSearchParams) {
                setSearchParamsIfDifferent(
                    'destinationFiltersMaxDurationSec',
                    destinationFilters.maxDurationSec ? String(destinationFilters.maxDurationSec) : null,
                );
                setSearchParamsIfDifferent(
                    'destinationFiltersMinDurationSec',
                    destinationFilters.minDurationSec ? String(destinationFilters.minDurationSec) : null,
                );
                setSearchParamsIfDifferent(
                    'destinationFiltersMaxNumberTransfers',
                    destinationFilters.maxNumberTransfers !== undefined
                        ? String(destinationFilters.maxNumberTransfers)
                        : null,
                );
                // setSearchParamsIfDifferent(
                //     'destinationFiltersBoundsNorthEastSouthWest',
                //     destinationFilters.boundsNorthEastSouthWest
                //         ? destinationFilters.boundsNorthEastSouthWest.join(',')
                //         : null,
                // );
            }
        },
        [],
    );

    const readSearchParams = (): DestinationListApiParams => {
        const apiParams: any = {
            nbrResults: NBR_RESULTS_PER_PAGE,
        };

        const searchParams = new URLSearchParams(window.location.search);

        apiParams.departureCitySlug = searchParams.get('departureCity') || '';
        setDepartureCitySlug(apiParams.departureCitySlug);

        const arrivalCitySlugs = searchParams.get('arrivalCities') ? searchParams.get('arrivalCities')!.split(',') : [];

        if (arrivalCitySlugs.length > 0) {
            apiParams.arrivalCitySlugs = arrivalCitySlugs.join(',');
        }

        const interests = searchParams.get('interests')
            ? searchParams
                  .get('interests')!
                  .split(',')
                  .filter((interest) => Object.values(Interest).includes(interest as Interest))
                  .map((interest) => Interest[interest as keyof typeof Interest]) || []
            : [];
        if (interests.length > 0) {
            apiParams.interests = interests.join(',');
        }
        setInterests(interests);

        const departureDateTime = searchParams.get('departureDateTime')
            ? new Date(searchParams.get('departureDateTime')!)
            : undefined;
        if (departureDateTime) {
            apiParams.departureDateTime = departureDateTime;
        }
        setDepartureDate(departureDateTime);

        const destinationFilters_: DestinationFilters = {};
        if (searchParams.get('destinationFiltersMaxDurationSec')) {
            const maxDurationSec = parseInt(searchParams.get('destinationFiltersMaxDurationSec')!);

            if (maxDurationSec) {
                destinationFilters_.maxDurationSec = maxDurationSec;
                apiParams.maxDurationSec = maxDurationSec;
            }
        }
        if (searchParams.get('destinationFiltersMinDurationSec')) {
            const minDurationSec = parseInt(searchParams.get('destinationFiltersMinDurationSec')!);

            if (minDurationSec) {
                destinationFilters_.minDurationSec = minDurationSec;
                apiParams.minDurationSec = minDurationSec;
            }
        }
        if (searchParams.get('destinationFiltersMaxNumberTransfers')) {
            const maxNumberTransfers = parseInt(searchParams.get('destinationFiltersMaxNumberTransfers')!);

            if (!isNaN(maxNumberTransfers)) {
                destinationFilters_.maxNumberTransfers = maxNumberTransfers;
                apiParams.maxNumberTransfers = maxNumberTransfers;
            }
        }

        // if (searchParams.get('destinationFiltersBoundsNorthEastSouthWest')) {
        //     const boundsNorthEastSouthWestStr = searchParams
        //         .get('destinationFiltersBoundsNorthEastSouthWest')!
        //         .split(',');

        //     if (boundsNorthEastSouthWestStr.length === 4) {
        //         const boundsNorthEastSouthWest = [];

        //         for (const boundStr of boundsNorthEastSouthWestStr) {
        //             const bound = parseFloat(boundStr);

        //             if (bound) {
        //                 boundsNorthEastSouthWest.push(bound);
        //             }
        //         }

        //         if (boundsNorthEastSouthWest.length === 4) {
        //             destinationFilters_.boundsNorthEastSouthWest = boundsNorthEastSouthWest as [
        //                 number,
        //                 number,
        //                 number,
        //                 number,
        //             ];
        //         }
        //     }
        // }

        setDestinationFilters(destinationFilters_);

        return apiParams;
    };

    const value = useMemo(() => {
        return {
            ...state,
            setDestinations,
            setDepartureCity,
            setInterests,
            setDepartureDate,
            setDepartureCitySlug,
            setArrivalCitySlugs,
            setDestinationFilters,
            readSearchParams,
        };
    }, [state, setDestinations, setDepartureCity]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { useContext_ as useSearchContext, Provider as SearchContextProvider };
